<template>
  <div>
    <!-- TODO BB-879 Change titleEditRoute -->
    <BasicDetailHeaderComponent :promises.sync="promises" default-avatar="default-task-avatar.svg"
      :title="basicTask.taskName"
      :titleEditRoute="{ name: routeNames.TASK_DETAIL_EDIT_TASK_NAME, params: { projectId: basicTask.projectId, clientId: basicTask.clientId, functionalId: basicTask.functionalId } }"
      :return-link-route="{ name: routeNames.PROJECT_DETAIL, params: { clientId: basicTask.clientId, projectId: basicTask.projectId, functionalId: basicTask.functionalId } }"
      return-link-text="Terug naar project" :active="basicTask.taskStatus && basicTask.taskStatus === 'ACTIVE'"
      active-text="Actieve taak" inactive-text="Inactieve taak" menu-button-text="Acties">

      <template v-slot:content>
        <v-col cols="12">
          Id: <Editable :route="getEditIdRoute" class="notranslate">{{ basicTask.functionalId }}</Editable>
        </v-col>
        <v-col cols="12">
          Klant: <router-link :to="getClientRoute" class="clickable">{{ basicTask.clientName }}</router-link>
        </v-col>
        <v-col cols="12">
          Project: <Editable :route="getEditProjectRoute" class="notranslate"><router-link :to="getClientRoute"
              class="clickable">{{ basicTask.projectName }}</router-link></Editable>
        </v-col>
      </template>

      <template #status-chip>
        <v-chip :color="getTaskStatusColor(basicTask.taskStatus)" label large class="h-100">
          {{ translateTaskStatus(basicTask.taskStatus) }}
        </v-chip>
      </template>

      <template v-slot:actions>
        <v-list-item link  @click="onToggleActiveClick">
          <v-list-item-icon><v-icon>mdi-list-status</v-icon></v-list-item-icon>
          <v-list-item-title>Verander status</v-list-item-title>
        </v-list-item>

        <v-list-item link @click="onDeleteTaskClick">
          <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
          <v-list-item-title>Verwijder</v-list-item-title>
        </v-list-item>
      </template>
    </BasicDetailHeaderComponent>

    <ConfirmationDialog :title="getDeleteDialogTitle" :description="getDeleteDialogDescription" v-model="deleteDialog"
      :onConfirm="deleteTask" />
  </div>
</template>

<script>
import BasicDetailHeaderComponent from '../shared/BasicDetailHeaderComponent.vue'
import routeNames from "@/router/RouteNames"
import ConfirmationDialog from "@/components/shared/ConfirmationDialog.vue"
import Editable from "@/components/shared/Editable"
import { getTaskStatusColor, translateTaskStatus } from '../../shared/utils/translateUtils'

export default {
  name: "TaskDetailHeaderComponent",
  components: {
    BasicDetailHeaderComponent,
    ConfirmationDialog,
    Editable,
  },
  props: {
    clientId: {
      type: String,
      required: true
    },
    projectId: {
      type: String,
      required: true
    },
    functionalId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      promises: [
        this.fetchBasicTask(),
      ],
      routeNames,
      basicTask: {
        taskName: null,
        functionalId: null,
        clientName: null,
        clientId: null,
        projectName: null,
        projectId: null,
        taskStatus: null
      },
      deleteDialog: false
    }
  },
  methods: {
    translateTaskStatus,
    getTaskStatusColor,
    fetchBasicTask() {
      this.$store.dispatch("tasksModule/fetchTaskBasic", { clientId: this.clientId, projectId: this.projectId, functionalId: this.functionalId })
        .then(result => this.basicTask = result)
    },
    onDeleteTaskClick() {
      this.deleteDialog = true
    },
    deleteTask() {
      this.promises.push(
        this.$store.dispatch("tasksModule/deleteTask", { clientId: this.basicTask.clientId, projectId: this.basicTask.projectId, taskId: this.basicTask.taskId })
          .then(() => this.$router.push(this.getProjectRoute))
      )
    },
    onToggleActiveClick() {
      this.$router.push(this.getEditStatusRoute)
    },
  },
  computed: {
    getDeleteDialogTitle() {
      return `Taak ${this.basicTask.taskName} verwijderen`
    },
    getDeleteDialogDescription() {
      return `Bent u zeker dat u de taak ${this.basicTask.taskName} wilt verwijderen?`
    },
    getClientRoute() {
      return { name: routeNames.CLIENT_DETAIL, params: { clientId: this.basicTask.clientId } }
    },
    getEditClientRoute() {
      return { name: routeNames.PROJECT_DETAIL_EDIT_PROJECT_CLIENT, params: { clientId: this.basicTask.clientId, projectId: this.basicTask.projectId } }
    },
    getProjectRoute() {
      return { name: routeNames.PROJECT_DETAIL, params: { clientId: this.basicTask.clientId, projectId: this.basicTask.projectId } }
    },
    getEditProjectRoute() {
      return {}
    },
    getEditIdRoute() {
      return { name: routeNames.TASK_DETAIL_EDIT_FUNCTIONAL_ID, params: { clientId: this.clientId, projectId: this.projectId, functionalId: this.functionalId } }
    },
    getEditStatusRoute() {
      return {name: routeNames.TASK_DETAIL_EDIT_TASK_STATUS, params: {clientId: this.basicTask.clientId, projectId: this.basicTask.projectId, functionalId: this.basicTask.functionalId}}
    }
  }
}
</script>